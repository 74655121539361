import React from 'react';
import Modal from '../Modal';
import Sortable from '../../Sortable';
import Loading from 'react-loading';
import SelectStack from '../../SelectStack';
import Checkbox from '../../Checkbox';
import CodeEditor from '../../CodeEditor';
import { toast } from 'react-toastify';

class ImportFileProvider extends Modal {
    state = {
        authenticated: false, 
        configurations: [],
        config: {},
        edit: -1, 
        oauth: null, 
        folders: [],
        expanded: [], 
        stacks: [],
        scriptTemplate: "",
        moveUploadedFiles: false
    }

    componentDidMount = async () => {
        this.props.loading(2000, "", false, 'import-file-provider')
        this.waitForTeamLoad = setInterval(async() => {
            if(!this.props.team || !this.props.team.sync)
                return; 
            
            // Team is loaded, clear interval
            clearInterval(this.waitForTeamLoad); 
            await this.props.loadStacks(); 
            await this.loadConfig(); 
            await this.authenticate(); 
            await this.loadRoot(); 
            this.props.loaded('import-file-provider');
        }, 200);
    }
    // *** FUNCTIONS BELOW MUST BE OVERRIDEN ***
    loadConfig = async() => {}
    authenticate = async() => {}
    loadRoot = async() => {}
    getOauth = async() => {}
    listFolders = async() => {}
    saveConfig = async() => {}

    // *** Helper Functions ***
    removeFirstSlash = (path) => {
        if (path[0] === '/')
            return path.substring(1);
        return path;
    }

    //*** Variables to override ***/
    scriptTemplate = ''

    // Function to show the modal description for sync integrations
    renderDescription = () => {}


    // Function below will handle the rendering of configs... 
    renderConfigEditor = () => {

        return (
            <div
                className='modal-content modal-center'
            >
                {this.renderDescription()}
                <div
                    className='config-form'
                >
                    {/* <div ** USE PROPS T
                        className='config'
                    >
                        <h1>User logged in to sync app</h1>
                        <SelectUser 
                            value={this.state.syncUser??''}
                            onChange={user => this.setState({syncUser: user})}
                            users={this.props.team?.users}
                        /> 
                        <div
                            className='hint'
                            style={{
                                paddingTop: 4
                            }}
                        >
                            <p>
                                What user account is logged in to the Desktop Sync App installed on the device you want to upload files from?
                            </p>
                        </div>
                    </div> */}
                    <h1>Source folder to scan</h1>
                    <div
                            style={{marginTop: 20, marginBottom: 20}}
                            key={Math.random()}
                        >
                            <Sortable 
                                title=''
                                data={this.state.configurations}

                                header={{
                                    name: {
                                        prop: 'source',
                                        label: 'Source folder'
                                    },
                                    meta: [
                                        {
                                            prop: '',
                                            label: 'Actions',
                                            width: 175,
                                            // width: ''
                                        },
                                    ]
                                }}

                                renderItem={(config, idx, renderMeta) => {
                                    return (
                                        <div
                                            className='sortable-list-item'
                                            key={idx}
                                            style={{ paddingLeft: 20 }}
                                        >
                                            <div
                                                className='sortable-list-name'
                                            >
                                                <img 
                                                    alt='folder'
                                                    src='/images/icons/folder.png'
                                                    style={{ 
                                                        marginRight: 15, 
                                                        width: 30,
                                                        position: 'relative',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)'
                                                    }}
                                                />
                                                {config?.source?.length? config.source: "Root"}
                                            </div>

                                            <div
                                                className='stack-list-meta'
                                            >
                                                {renderMeta(
                                                    <div
                                                        className='import-connections-actions'
                                                    >
                                                        <div
                                                            className='import-connections-action'
                                                            style={{
                                                                marginLeft: 10,
                                                                backgroundImage: "url('/images/icons/action-configure.png')"
                                                            }}
                                                            title="Edit connection configuration"
                                                            onClick={() => this.setState({edit: this.state.edit === idx? -1: idx, config: this.state.configurations[idx]})}
                                                        >
                                                            {this.state.edit === idx? "Cancel": "Edit"}
                                                        </div>
                                                        
                                                        <div
                                                            className='import-connections-action'
                                                            style={{
                                                                // marginLeft: 10,
                                                                backgroundImage: "url('/images/icons/delete.png')"
                                                            }}
                                                            title="Edit connection configuration"
                                                            onClick={() => {

                                                                const configurations = [...this.state.configurations]; 
                                                                configurations.splice(idx, 1);

                                                                this.setState({
                                                                    configurations,
                                                                    edit: -1, 
                                                                    config: {}
                                                                })
                                                            }}
                                                        >
                                                            Remove
                                                        </div>
                                                    </div>, 194)}
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                        {
                            this.state.edit < 0 &&
                            <div 
                                className="settings-add" 
                                style={{
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                    margin: 0

                                }}
                                onClick={() => this.setState({edit: this.state.configurations.length, config: {
                                    mirror: true,
                                    script: this.state.scriptTemplate
                                }})}
                            >
                                ＋ Add a new folder to scan
                            </div>
                        }
                        {this.state.edit >= 0 && <>
                            <div
                                className='config'
                            >
                                <label>Select Folder</label>
                                {
                                    this.state.loading? 
                                    <div
                                        className='config-stack'
                                        style={{display:'flex', justifyContent:'center', alignItems:'center'}}
                                    >
                                        <Loading 
                                            type='bubbles'
                                            color='var(--color-primary)'
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                    :
                                    <SelectStack 
                                        value={this.state.config?.id??''}
                                        onChange={stack => {
                                            const selected = this.state.folders.find(f => f._id === stack);
                                            this.setState({config: {...this.state.config, 
                                                name: selected.displayName??selected.name,
                                                source: selected.path,
                                                id: stack, 
                                                numDocuments: selected.numDocuments,
                                            }});
                                        }}
                                        stacks={this.state.folders}
                                        dynamic={true}
                                    />
                                }
                            </div>
                            <div
                                className='config'
                            >
                                <Checkbox 
                                    className="settings-checkbox"
                                    label="Mirror this folder in Stax·ai"
                                    checked={this.state.config?.mirror}
                                    onToggle={() => this.setState({config: {...this.state.config, mirror: !this.state.config?.mirror}})}
                                />
                            </div>
                            {
                                !this.state.config?.mirror &&
                                <>
                                <div
                                    className='config'
                                >
                                    <label>Scan Function</label>
                                    <CodeEditor 
                                        className="config-code"
                                        style={{  }}
                                        theme={this.props.user && this.props.user.enableDarkMode ? "twilight" : "xcode"}
                                        value={this.state.config?.script?? (this.scriptTemplate)}
                                        language='py'
                                        onChange={code => this.setState({config: {...this.state.config, script: code}})}
                                        width=''
                                    />
                                    <div
                                        className='hint'
                                        style={{
                                            paddingTop: 4
                                        }}
                                    >
                                        <p>
                                            Define a Python function to scan the folder and return a list of relative paths to files to upload. Leave it as is to upload all files in the folder.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className='config'
                                >
                                    <label>Destination stack</label>
                                    <SelectStack 
                                        value={this.state.config?.destination??''}
                                        onChange={stack => this.setState({config: {...this.state.config, destination: stack}})}
                                        stacks={this.props.stacks}
                                    /> 
                                    <div
                                        className='hint'
                                        style={{
                                            paddingTop: 4
                                        }}
                                    >
                                        <p>
                                            Choose the stack where you want to upload files to.
                                        </p>
                                    </div>
                                </div>
                                </>
                            }
                            <div
                                className='button'
                                style={{float: 'none', backgroundImage: 'url("/images/icons/plus.png")'}}
                                onClick={() => {

                                    // Confirm everything is filled out. 
                                    if(!this.state.config?.source?.length)
                                        return toast.error("Please select a folder to scan");
                                    if(!this.state.config?.destination?.length && !this.state.config?.mirror)
                                        return toast.error("Please select a destination stack");
                                    if(!this.state.config?.script?.length && !this.state.config?.mirror)
                                        return toast.error("Please define a scan function");


                                    const configurations = [...this.state.configurations]
                                    const exists = this.state.edit < configurations.length; 

                                    if(exists)
                                        configurations[this.state.edit] = this.state.config; 
                                    else
                                        configurations.push(this.state.config); 
                                    
                                    this.setState({
                                        configurations,
                                        edit: -1, 
                                        config: {}
                                    })
                                }}
                            >
                                {this.state.edit < this.state.configurations.length? "Update Configuration": "Add Configuration"}
                            </div>     
                        </>
                    }

                    </div>  

                    <div style={{ clear: 'both' }} />

                    <div style={{display: 'flex', alignContent: 'center' }} >
                        <div style={{marginLeft: 70}} className="import-checkbox-label">
                            Move uploaded files to the 'Upload Complete' folder after import
                        </div>
                        <Checkbox
                                //className="import-checkbox"
                                style={{marginRight: -10, top: 24}} 
                                tooltip="Move uploaded files to the 'Upload Complete' folder after import"
                                checked={this.state.moveUploadedFiles}
                                onToggle={() => this.setState({ moveUploadedFiles: !this.state.moveUploadedFiles })}
                            />
                    </div>
                
                    {this.state.edit < 0 && 
                        <input
                            className="import-config-button"
                            style={{
                                marginLeft: 0, 
                                float: 'none',
                                width: 220
                            }}
                            type="submit"
                            value="Save Configuration"
                            onClick={async() => {
                                this.props.loading(9999);
                                await this.saveConfig();
                                this.props.modal({});
                                this.props.loaded(); 
                                toast.success("Configuration saved");
                            }}
                        />
                    }

            </div>
        
        )
    }
}


export default ImportFileProvider; 